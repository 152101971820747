import differenceInDays from "date-fns/differenceInDays";
import React, { ForwardedRef, forwardRef, useEffect, useMemo, useState } from "react";
import useClx from "../../../../../hooks/use-clx";
import useOnce from "../../../../../hooks/use-once";
import usePathParams from "../../../../../hooks/use-path-params";
import { useQueryParam, useQueryParamValue, useQuerySearch } from "../../../../../hooks/use-query-param";
import { useUser } from "../../../../../hooks/use-session";
import Button from "../../../../Button";
import CouponCode from "../../../../CouponCode";
import DiscountAlert from "../../../../DiscountAlert";
import LoyaltyPointTicker from "../../../../DiscountAlert/LoyaltyPointTicker";
import Form from "../../../../Form";
import {
  AsyncDateRange,
  PropertyConfigSelect,
  OccupancySelect,
  Select,
} from "../../../../Form/Fields";
import LoyaltyPointShortTicker from "../../../../LoyaltyPointShortTicker";
import { usePriceCalculatorValue } from "../../../../../hooks/use-price-calculator";
import { generatePropertyConfigKey, usePropertyConfigValues } from "../../../../../hooks/use-property-config";
import { createPriceCalculatorSchema } from "./price-calculator-schema";
import clxs from "./price-calculator.module.css";
import searchParamsFromFormValues from "../search-params-from-form-values";
import { useLoginModal } from "../../../../../hooks/use-login-modal";
import useAnalytics from "../../../../../hooks/use-analytics";
import PreBookMeals from "../../MealPlanOptions/PreBookMeals";

interface PriceCalculatorProps {
  viewLayout: string;
  id: string;
  path: string;
  propertySlugKey: string;
  checkinDateKey: string;
  checkoutDateKey: string;
  adultCountKey: string;
  childCountKey: string;
  couponCodeKey: string;
  maxAdultCount: number;
  maxChildCount: number;
  className?: string;
  hideCouponCode?: boolean;
  hideLoyaltyPointsTicker?: boolean;
  configs: PropertyConfig[];
  mealRef?:React.RefObject<HTMLDivElement>;
  isVegOrNonVegAvailable?: boolean;
  onSubmit: (value: any) => void;
  onSendRequest: (value: any) => void;
  average_ratings?: number;
  reviews_count?: number;
}

const PriceCalculator = forwardRef(
  function PriceCalculatorCore(
    props: PriceCalculatorProps,
    ref: ForwardedRef<HTMLFormElement>,
  ) {
    const {
        viewLayout,
        path,
        id,
        propertySlugKey,
        checkinDateKey,
        checkoutDateKey,
        adultCountKey,
        childCountKey,
        couponCodeKey,
        configs,
        hideCouponCode = false,
        hideLoyaltyPointsTicker = false,
        className,
        maxAdultCount,
        maxChildCount,
        mealRef,
        isVegOrNonVegAvailable,
        onSubmit,
        onSendRequest,
        average_ratings,
        reviews_count,
      } = props,
      [_, setQuerySearch] = useQuerySearch(),
      { track } = useAnalytics(),
      propertySlug = usePathParams(path, propertySlugKey),
      checkinDate = useQueryParamValue("date", checkinDateKey),
      checkoutDate = useQueryParamValue("date", checkoutDateKey),
      adultCount = useQueryParamValue("integer", adultCountKey, 2),
      childCount = useQueryParamValue("integer", childCountKey),
      preBookMeals = useQueryParamValue("boolean", queryKey),
      showLoginFlowModal = useLoginModal(),
      configSelectionDisabled = useMemo(
        () => !checkinDate || !checkoutDate,
        [checkinDate, checkoutDate],
      ),
      [couponCode, setCouponCode] = useQueryParam("string", couponCodeKey),
      {
        values: config_args_search_values,
        configMaxCount,
      } = usePropertyConfigValues(propertySlug, configs),
      packageOptions = useMemo(
        () => {
          const options: any[] =  configs.map(
            config => {
              const key = generatePropertyConfigKey(propertySlug, config);
              
              return {
                label: `${config.room_name}`,
                value: key,
              };
            },
          );
          
          return options;
        },
        [propertySlug, configs],
      ),
      [isDisableBooking,setIsDisableBooking] = useState<boolean>(false),
      defaultValue = useMemo(
        () => {
          /* 
          config_args_search_values = { bastora_5: "1", bastora_7: "0" } -> bastora_5
          */
          const packageSlug = inferPackageSlugFromConfigArgs(config_args_search_values);

          return {
            stay: {
              start: checkinDate,
              end: checkoutDate,
            },
            occupancy: {
              adult_count: adultCount,
              child_count: childCount,
            },
            config_args: config_args_search_values,
            package_slug: packageSlug,
            pre_book_meals: preBookMeals,
          };
        },
        [configs, config_args_search_values],
      ),
      { tentativePrice } = usePriceCalculatorValue(id),
      currency = useMemo(
        () => tentativePrice?.currency_symbol || "",
        [tentativePrice],
      ),
      isInvalid = useMemo(
        () => !tentativePrice,
        [tentativePrice],
      ),
      isUnavailable = useMemo(
        () => tentativePrice?.status === "unavailable",
        [tentativePrice],
      ),
      total = useMemo(
        () => {
          const total = tentativePrice?.total || 0;

          if (!total) {
            setIsDisableBooking(true);
            return "-";
          }

          setIsDisableBooking(false);
          return `${currency} ${total.toLocaleString("en-IN")}`;
        },
        [tentativePrice],
      ),
      isShowPricing = useMemo(
        () => !isUnavailable || total,
        [total],
      ),
      discounted = useMemo(
        () => {
          const discountedTotal = tentativePrice?.discounted_total;

          if (discountedTotal === undefined) {
            return null;
          }

          const total = tentativePrice?.total || 0;

          if (discountedTotal === total) {
            return null;
          }

          return `${currency} ${discountedTotal.toLocaleString("en-IN")}`;
        },
        [tentativePrice],
      ),
      superscript = useMemo(
        () => {
          if (checkinDate && checkoutDate) {
            return "Total payable";
          }

          return "Starts from";
        },
        [checkinDate, checkoutDate],
      ),
      totalNights = useMemo(
        () => {
          if (!checkinDate || !checkoutDate) {
            return 0;
          }

          const diff = differenceInDays(checkoutDate, checkinDate);

          return diff;
        },
        [checkinDate, checkoutDate],
      ),
      subscript = useMemo(
        () => {
          if (!totalNights) {
            return "/ night";
          }

          return ` / ${totalNights} nights`;
        },
        [totalNights],
      ),
      actionText = useMemo(
        () => {
          if (isUnavailable) {
            return "Enquire";
          }

          if (tentativePrice?.sold_out) {
            return "Enquire";
          }

          return "Book";
        },
        [tentativePrice],
      ),
      validationSchema = useMemo(
        () => createPriceCalculatorSchema(
          propertySlug,
          configs,
          tentativePrice?.sold_out ?? false,
          viewLayout,
        ),
        [configs, tentativePrice],
      ),
      user = useUser(),
      llpDiscountMethod = useMemo(
        () => {
          const discountMethod = tentativePrice?.breakdown.discount_method;

          if (discountMethod === "coupon") {
            return user?.loyalty_point_metadata.tier;
          }

          return discountMethod;
        },
        [
          tentativePrice?.breakdown.discount_method,
          user?.loyalty_point_metadata.tier,
        ],
      ),
      ccx = useClx(clxs.container, className),
      handleChange = (values: any) => {
        const payload = searchParamsFromFormValues(
          propertySlug,
          viewLayout,
          configs,
          values,
        );

        setQuerySearch(() => payload, { replace: true });
      },
      handleCouponCodeChange = (couponCode: string) => {
        return setCouponCode(couponCode);
      },
      handleAutoApplyCoupon = () => {
        if (!tentativePrice) {
          return false;
        }

        if (couponCode === "NONE") {
          return true;
        }

        const { coupon_code: cc } = tentativePrice.breakdown;

        if (!cc) {
          return true;
        }

        handleCouponCodeChange(cc);

        return true;
      },
      checkoutFunction = (value: any) => {
        if (isInvalid) {
          return;
        }

        if (tentativePrice?.sold_out || isUnavailable) {
          return onSendRequest(value);
        }

        return onSubmit(value);
      },
      getTrackPricePayloadObj = () => {
        return tentativePrice;
      },
      getTotalRooms = (configArgs: any) => {
        let totalRooms = 0;
        
        if(viewLayout === "multi_config") {
          Object.keys(configArgs).forEach((key) => {
            totalRooms = totalRooms + configArgs[key];
          });
        } else {
          Object.keys(configArgs).forEach((key) => {
            if(configArgs[key] > 0) {
              const configId = key.split("_")[1];
              configs.forEach((oConfig) => {
                if(oConfig.config_id == configId) {
                  totalRooms = totalRooms + oConfig.bedrooms;
                }
              })
            }
          });
        }

        return totalRooms;
      },

      /**
       * Handles the submission of booking with appropriate actions based on user  authentication      status.
       * @param value - The booking details object.
      */
      handleSubmit = (value: any) => {

        const trackingPayload = {
          tentativePrice: getTrackPricePayloadObj(),
          property_type: viewLayout,
          totalNights: totalNights,
          totalRooms: getTotalRooms(value.config_args),
          meal_plan_opt: preBookMeals,
          ratings: average_ratings ? average_ratings : null,
          reviews_count: reviews_count ? reviews_count : null,
        };

        const ratingsTrackingPayload = {
          ratings: average_ratings ? average_ratings : null,
          reviews_count: reviews_count ? reviews_count : null,
        }

        // localStorage.setItem("trackingPayload", JSON.stringify(trackingPayload));
        localStorage.setItem("ratingsPayload", JSON.stringify(ratingsTrackingPayload));

      // Check if the user is not logged in and show login popup before proceeding to checkout
         if((!user) || (user && (!user?.full_name || !user?.email))) {
          const modalProps = {
            className: "",
            onSubmit: checkoutFunction,
          }

          return showLoginFlowModal(modalProps).then((success: any) => {
            if(success) {
              //Firebase event - book_now
              track("book_now_property_clicked", trackingPayload);
              checkoutFunction(value);
            }
          })
        }else { //If the additional details is already filled is and user already logged in proceed to checkout on successful login
          //Firebase event - book_now
          track("book_now_property_clicked", trackingPayload);
          return checkoutFunction(value);
        }  
        
      },
      handleMealOptChange = (isChecked: boolean) => {
        //If meal plan is opted, sending an event.
        if(isChecked) {
          const trackingPayload = {
            tentativePrice: getTrackPricePayloadObj(),
            property_type: viewLayout,
            totalNights: totalNights,
            totalRooms: getTotalRooms(configs),
            meal_plan_opt: isChecked,
            ratings: average_ratings ? average_ratings : null,
            reviews_count: reviews_count ? reviews_count : null,
          };
    
          track("meals_interested", trackingPayload)
        }
      };

    useEffect(() => {
      if(preBookMeals) {
        handleMealOptChange(preBookMeals);
      }
    },[preBookMeals]);  

    useOnce(handleAutoApplyCoupon, [tentativePrice]);

    return (
      <Form
        id={FORM_ID}
        className={ccx}
        defaultValue={defaultValue}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        onChange={handleChange}
        ref={ref}
        enableReinitialize={true}
      >
        <DiscountAlert
          message={tentativePrice?.breakdown.discount_message}
          method={tentativePrice?.breakdown.discount_method}
          className={clxs.discount}
          showChildRegardless={true}
        >
          <LoyaltyPointTicker
            className={clxs.llpTicker}
            method={llpDiscountMethod}
            showForMethodCoupon={true}
            visible={!hideLoyaltyPointsTicker}
          />
        </DiscountAlert>
        {(isShowPricing) && (
          <div className={clxs.price}>
            <div>{superscript}</div>
            <div>
              {discounted && (
                <span className={clxs.strike}>
                {total}
              </span>
              )}
              <span className={clxs.rate}>
                {discounted || total}
              </span>
              {subscript}
              <sup>*</sup>
            </div>
            <div className={clxs.excTax}>
              <LoyaltyPointShortTicker
                method={tentativePrice?.breakdown.discount_method}
                discountPercentage={tentativePrice?.breakdown.loyalty_points_discount_percentage}
                className={clxs.llpShortTicker}
              />
              * Exclusive of taxes
            </div>
          </div>
        )}
        <AsyncDateRange
          form={FORM_ID}
          name="stay"
          labels={["Check-In", "Check-Out"]}
          propertySlug={propertySlug}
          calendarDialogProps={{ monthCount: 2 }}
        />
        <OccupancySelect
          form={FORM_ID}
          name="occupancy"
          label="Guests"
          maxAdultCount={maxAdultCount}
          maxChildCount={maxChildCount}
        />
        {viewLayout === "multi_config" && (
          <PropertyConfigSelect
            form={FORM_ID}
            label="Rooms *"
            name="config_args"
            propertySlug={propertySlug}
            configs={configs}
            maxCount={configMaxCount}
            disabled={configSelectionDisabled}
          />
        )}
        {viewLayout === "package" ? (
          <Select
            form={FORM_ID}
            label="Package *"
            name="package_slug"
            options={packageOptions}
            searchable={false}
          />
        ) : null}
        {!hideCouponCode && (
          <CouponCode
            value={couponCode}
            propertySlug={propertySlug}
            checkinDate={checkinDate?.toISOString()}
            checkoutDate={checkoutDate?.toISOString()}
            adultCount={adultCount ?? undefined}
            childCount={childCount ?? undefined}
            onChange={handleCouponCodeChange}
            className={clxs.couponCode}
          />
        )}
        {isVegOrNonVegAvailable ? 
        <PreBookMeals 
          queryKey={queryKey}
          className={clxs.preBookMeals}
          theme="priceCalculator"
          form={FORM_ID}
          mealRef={mealRef}
        /> : null }
        <div className={clxs.bookContainer}>
          <Button
            className={clxs.book}
            type="submit"
            disabled={isDisableBooking}
          >
            {actionText}
          </Button>
        </div>
      </Form>
    );
  },
);

export default PriceCalculator;

const FORM_ID = "rate-calculator-desktop";

const queryKey = "pre_book_meals";

function inferPackageSlugFromConfigArgs(
  configArgs: Record<string, number>,
) {
  for (const [configKey, requiredRooms] of Object.entries(configArgs)) {
    if (requiredRooms > 0) {
      return configKey;
    }
  }

  return "";
}
