import React, { useCallback, useMemo, useRef } from "react";
import useContactUsPhoneNo from "../../../hooks/use-contact-us-phone-no";
import useIsEH from "../../../hooks/use-is-eh";
import usePropertySimilar from "../../../hooks/use-property-similar";
import Portal from "../../Portal";
import Recommendation from "../../Recommendation";
import SecurePayment from "../../SecurePayment";
import SendRequest from "../../SendRequestNeedHelp";
import HouseRule from "../HouseRule";
import PropertyBanner from "../PropertyBanner";
import Specification from "../Specification";
import Amenities from "./Amenities";
// import GalleryPreview from "./GalleryPreview";
import PriceCalculatorDesktop from "./PriceCalculator/Desktop";
import PriceCalculatorMobile from "./PriceCalculator/Mobile";
import RoomLayout from "./RoomLayout";
import RoomOptions from "./RoomOptions";
import ThingsToDo from "./ThingsToDo";
import useCalculatorFocus from "./use-calculator-focus";
import YoutubeVideo from "./YoutubeVideo";
import WellnessPromotion from "./WellnessPromotion";
import { useDefaultPropertyConfig } from "../../../hooks/use-property-config";
import Modal, { useShowModal } from "../../../hooks/use-modal";
import RoomDetails from "./RoomDetails";
import useRoomDetails from "../../../hooks/use-room-details";
import { usePriceCalculatorValue } from "../../../hooks/use-price-calculator";
import clxs from "./lohono-layout.module.css";
import SeniorCitizen from "./SeniorCitizen";
import Description from "./Description";
import useIsMobile from "../../../hooks/use-is-mobile";
import MealPlanOptions from "./MealPlanOptions";
import MealOptions from "./MealOptions";
import useMealPlanOptions from "../../../hooks/use-meal-plan-options";
import GuestReviews from "../GuestReviews";
import usePropertyReviews from "../../../hooks/use-property-reviews";
import PropertyRatings from "./PropertyRatings";

interface LohonoLayoutProps {
  priceCalculatorId: string;
  path: string;
  locationSlug: string;
  propertyDetails: Property;
  onShowGallery: () => void;
  onShare: () => Promise<boolean>;
  onSendRequest: (values: any) => void;
  onBook: (values: any) => void;
  propertySlugKey: string;
  checkinDateKey: string;
  checkoutDateKey: string;
  adultCountKey: string;
  childCountKey: string;
  couponCodeKey: string;
  availLoyaltyPointsKey: string;
  seniorCitizenDescription: string;
  seniorCitizenFriendly: boolean;
}

function LohonoLayout (props: LohonoLayoutProps) {
  const {
    priceCalculatorId,
    path,
    locationSlug,
    propertyDetails,
    onShowGallery,
    onShare,
    onSendRequest,
    onBook,
    propertySlugKey,
    checkinDateKey,
    checkoutDateKey,
    adultCountKey,
    childCountKey,
    couponCodeKey,
    seniorCitizenDescription,
    seniorCitizenFriendly,
  } = props,
    { isMobile } = useIsMobile(),
    dcx = isMobile ? (clxs.mobileDivider) : (clxs.divider),
    roomOptionsRef = useRef<HTMLDivElement>(null),
    mealRef = useRef<HTMLDivElement>(null),
    reviewRef = useRef<HTMLDivElement>(null),
    showRoomDetailsModal = useShowModal(ROOM_DETAILS_MODAL_ID),
    getRoomDetails = useRoomDetails(),
    { slug: propertySlug } = propertyDetails,
    { isEH } = useIsEH(locationSlug),
    {
      view_layout,
      name,
      address,
      banner,
      accommodation,
      bath_count,
      pool_count,
      adult_count, // Max adult count
      child_count,  // Max child count
      description,
      featured_in,
      top_highlights,
      meal_options,
      // images,
      amenities,
      bedrooms,
      bedroom_count,
      nearby_places,
      lat,
      lng,
      google_map_link,
      house_rules,
      cancellation_policy,
      upcoming,
      builder,
      configs,
      youtube_video_id,
      ratings_count,
      average_ratings,
    } = propertyDetails,
    {
      veg,
      non_veg,
    } = meal_options,
    {mealPlanOptions} = useMealPlanOptions(propertySlug),
    {reviews} = usePropertyReviews(propertySlug),
    { similarProperties } = usePropertySimilar(propertySlug, isEH,adultCountKey, childCountKey,checkinDateKey,checkoutDateKey),
    highlights = useMemo(
      () => {
        return top_highlights.slice(0, 5);
      },
      [top_highlights],
    ),
    isVegOrNonVegAvailable = useMemo(() => {
      return !!((mealPlanOptions && (mealPlanOptions.veg.length || mealPlanOptions.non_veg.length || mealPlanOptions.veg_non_veg.length)));
    }, [veg, non_veg, mealPlanOptions]),
    safeViewLayout = useMemo(
      () =>  view_layout,
      [configs, view_layout],
    ),
    isMultiConfig = useMemo(
      () => safeViewLayout === "multi_config",
      [safeViewLayout],
    ),
    isShowPriceCalculator = useMemo(
      () => {
        if(!isMobile) {
          return true;
        }
        if(isMobile && isMultiConfig) {
          return true;
        }
        return false;
      }, [isMultiConfig,isMobile],
    ),
    hasWellnessPromotion = useMemo(
      () => (WELLNESS_PROMOTION_PROPERTIES.includes(propertyDetails.slug)),
      [locationSlug],
    ),
    isMaharajahHouse = useMemo(
      () => (propertyDetails.slug === "maharajah-house"),
      [locationSlug],
    ),
    showVideo = useMemo(
      () => Boolean(youtube_video_id).valueOf(),
      [youtube_video_id],
    ),
    calculatorRef = useCalculatorFocus(),
    { friendly: contactUsPhoneNo, tel } = useContactUsPhoneNo(),
    { tentativePrice } = usePriceCalculatorValue(priceCalculatorId),
    handleViewRoomDetails = useCallback(
      async (config: PropertyConfig) => {
        const roomDetails = await getRoomDetails(
          propertySlug,
          config.config_id,
        );

        if (!roomDetails) {
          return;
        }

        await showRoomDetailsModal({ roomDetails: roomDetails });
      },
      [showRoomDetailsModal, getRoomDetails],
    ),
    onMobileChange = () => {
      roomOptionsRef.current?.scrollIntoView();
    };

  useDefaultPropertyConfig(propertySlug, safeViewLayout, configs);

  return (
    <>
      <div className={clxs.container}>
        <PropertyBanner
          banner={banner}
          builder={builder}
          name={name}
          address={address}
          className={clxs.banner}
          onViewPhotos={onShowGallery}
          onShare={onShare}
          videoAnchorId={VIDEO_ANCHOR_ID}
          showVideoLink={showVideo}
        />
        <div className={clxs.titleContainer}>
          <h1 className={clxs.title}>
            {name}
            {upcoming && (
              <span className={clxs.upcoming}>
                Coming soon
              </span>
            )}
          </h1>
          <div className={clxs.subtitle}>
            {address}
            {google_map_link && (
              <a
                href={google_map_link}
                className={clxs.mapLink}
                target="_blank"
                rel="noopener noreferrer"
                title="View on Map"
              >
                View on Map
              </a>
            )}
          </div>
          {ratings_count && average_ratings ?
          // Check if both ratings_count and average_ratings are truthy
          // If true, render the PropertyRatings component
          <PropertyRatings
            className={clxs.propertyRatings}
            ratings_count={ratings_count}
            average_ratings={average_ratings}
            reviewRef={reviewRef}
          />
          : null }
        </div>
        <Specification
          viewLayout={safeViewLayout}
          accommodation={accommodation}
          bathCount={bath_count}
          bedroomCount={bedroom_count}
          configs={isMultiConfig ? [] : configs}
          poolCount={pool_count}
        />
        <hr
          className={dcx}
          data-active={true}
        />
        {highlights.length ? (
          <div className={clxs.highlightsContainer}>
            {highlights.map((each, key) =>
              <div
                key={key}
                className={clxs.highlights}
              >
                <img
                  src={each.icon}
                  alt={each.name}
                  className={clxs.highlightsIcon}
                />
                <div className={clxs.highlightsTitle}>
                  {each.name}
                </div>
              </div>,
            )}
          </div>
        ) : null
        }
        {/* one more condition to check for meal plan options if meal plan are present hide this section */}
        {((veg || non_veg) && (!mealPlanOptions?.veg?.length && !mealPlanOptions?.non_veg?.length)) ? (
          <MealOptions mealOptions={meal_options} />
        ) : null}
        {(((veg || non_veg) || top_highlights.length) ?
          (
            <hr
              className={dcx}
              data-active={true}
            />
          ) : null
        )}
        <div>
          <Description description={description} />
          {showVideo && (
            <YoutubeVideo
              anchorId={VIDEO_ANCHOR_ID}
              youtubeVideoId={youtube_video_id}
            />
          )}
        </div>
        <hr
          className={dcx}
          data-active={true}
        />
        {hasWellnessPromotion && (
          <WellnessPromotion propertySlug={propertySlug} />
        )}
        {isMaharajahHouse &&
          <div>
            <h3 className={clxs.mhTitle}>{MAHARAJAH_HOUSE_TITLE}</h3>
            <p className={clxs.mhDescription}>
              {MAHARAJAH_HOUSE_DESCRIPTION_P1}
            </p>
            <p className={clxs.mhDescription}>
              {MAHARAJAH_HOUSE_DESCRIPTION_P2}
            </p>
          </div>
        }
        {featured_in && (
          <div className={clxs.featured}>
            Featured in
            <div className={clxs.featuredTitle}>
              {featured_in}
            </div>
          </div>
        )}
        {/* <GalleryPreview
          images={images}
          onShowGallery={onShowGallery}
        /> */}
        {isMultiConfig ? (
          <RoomOptions
            ref={roomOptionsRef}
            configs={configs}
            propertySlug={propertySlug}
            tentativePrice={tentativePrice}
            checkinDateKey={checkinDateKey}
            checkoutDateKey={checkoutDateKey}
            onViewRoomDetails={handleViewRoomDetails}
          />
        ) : (
          <RoomLayout
            bedrooms={bedrooms}
            carouselId={BEDROOM_DETAIL_ID}
            className={clxs.bedroomDetails}
            titleClassName={clxs.bedroomDetailsTitle}
            containerClassName={clxs.bedroomCarousel}
            title="Bedroom Details"
          />
        )}
         {isVegOrNonVegAvailable ? (
          <MealPlanOptions
            ref={mealRef}
            mealDescription={ non_veg ? "" : "This Villa has Veg food only."}
            mealOptions={meal_options}
            id={priceCalculatorId}
            mealOptionList={mealPlanOptions}
          /> 
        ) : null}
        {seniorCitizenFriendly && (
          <SeniorCitizen seniorCitizenDescription={seniorCitizenDescription} />
        )}
        {amenities.length ? (<Amenities amenities={amenities} />): null}
        { (isShowPriceCalculator || upcoming) && (
          <div className={clxs.calculatorContainer}>
            <div className={clxs.stickyContainer}>
              {upcoming ? (
                <SendRequest
                  className={clxs.sendRequest}
                  title="Enquire now"
                  interest="rental"
                />
              ) : (
                // Desktop Calculator Only Visible in case of multi config since we have not provided multi config options in price calcultor of mobile.
                // Once options availabel this will be commented for Mobile
                isShowPriceCalculator &&
                <>
                  <PriceCalculatorDesktop
                    viewLayout={safeViewLayout}
                    id={priceCalculatorId}
                    path={path}
                    couponCodeKey={couponCodeKey}
                    propertySlugKey={propertySlugKey}
                    checkinDateKey={checkinDateKey}
                    checkoutDateKey={checkoutDateKey}
                    adultCountKey={adultCountKey}
                    childCountKey={childCountKey}
                    maxAdultCount={adult_count || 24}
                    maxChildCount={child_count || 8}
                    configs={configs}
                    className={clxs.calculatorDesktop}
                    onSendRequest={onSendRequest}
                    onSubmit={onBook}
                    ref={calculatorRef}
                    hideLoyaltyPointsTicker={isEH}
                    mealRef= {mealRef}
                    isVegOrNonVegAvailable={isVegOrNonVegAvailable}
                    average_ratings={average_ratings}
                    reviews_count={ratings_count}
                  />
                  <SecurePayment />
                  <button
                    className={clxs.needHelp}
                    onClick={onSendRequest.bind(null, undefined)}
                  >
                    Need help?
                  </button>
                </>
              )}
              {isShowPriceCalculator &&
                <div className={clxs.callUs}>
                  Call us on <a href={tel}>
                    {contactUsPhoneNo}
                  </a>
                </div>
              }
            </div>
          </div>
        )}
        {nearby_places.length ? (
          <>
            <hr
              className={dcx}
              data-active={false}
            />
            <ThingsToDo
              name={name}
              lat={lat}
              lng={lng}
              nearbyPlaces={nearby_places}
            />
          </>
        ) : null}
        
          {/* If there are no reviews, render nothing */}
        {reviews.length ? <div className={clxs.reviewContainer}>
          {/* Render a horizontal line for mobile devices */}
          {/* {isMobile ? */}
          <hr
            className={dcx}
            data-active={true}
          /> 
           <GuestReviews
             ref={reviewRef}
             reviews={reviews}
             className={clxs.reviews}
             average_ratings={average_ratings}
             ratings_count={ratings_count}
             title="Guest Reviews"
             isSticky={isMobile}
             configs={configs}
           /> </div> : null}
        
        {house_rules.length ? (
          <div>
            <hr
              className={dcx}
              data-active={true}
            />
            <HouseRule
              maxVisible={isMobile ? 0 : 3}
              title="House rules"
              houseRules={house_rules}
              isSticky={isMobile}
            />
          </div>
        ) : null}
        {cancellation_policy.length ? (
          <div className={clxs.cancellationPolicy}>
            <hr
              className={dcx}
              data-active={true}
            />
            <HouseRule
              maxVisible={isMobile ? 0 : 3}
              title="Cancellation policy"
              houseRules={cancellation_policy}
              isSticky={isMobile ? true : false}
            />
          </div>
        ) : null}
        {/* {featured_in ? null : (<div>&nbsp;</div>)}
        {nearby_places.length ? null : (<div>&nbsp;</div>)}
        {house_rules.length ? null : (<div>&nbsp;</div>)}
        {cancellation_policy.length ? null : (<div>&nbsp;</div>)} */}
        <hr
          className={dcx}
          data-active={true}
        />
        <Recommendation
          title="Similar properties"
          carouselId={SIMILAR_CAROUSEL_ID}
          properties={similarProperties}
          checkinDateKey={checkinDateKey}
          checkoutDateKey={checkoutDateKey}
          adultCountKey={adultCountKey}
          childCountKey={childCountKey}
          className={clxs.similarProperties}
          titleClassName={clxs.similarPropertiesTitle}
          containerClassName={clxs.similarCarousel}
          tracking="property_detail_similar_clicked"
        />
      </div>
      <Portal className={clxs.calculatorMobile}>
        {upcoming ? (
          <div className={clxs.enquireContainer}>
            <button
              className={clxs.enquireSecondary}
              onClick={onSendRequest.bind(null, undefined)}
            >
              Enquiry
            </button>
            <a
              href={tel}
              className={clxs.callUsSecondary}
            >
              Call us
            </a>
          </div>
        ) : (
          <PriceCalculatorMobile
            viewLayout={safeViewLayout}
            id={priceCalculatorId}
            checkinDateKey={checkinDateKey}
            checkoutDateKey={checkoutDateKey}
            adultCountKey={adultCountKey}
            childCountKey={childCountKey}
            maxAdultCount={adult_count || 24}
            maxChildCount={child_count || 8}
            configs={configs}
            propertySlug={propertySlug}
            mealRef= {mealRef}
            isVegOrNonVegAvailable={isVegOrNonVegAvailable}
            onSendRequest={onSendRequest}
            onSubmit={onBook}
            onChange={onMobileChange}
            average_ratings={average_ratings}
            reviews_count={ratings_count}
          />
        )}
      </Portal>
      <Modal id={ROOM_DETAILS_MODAL_ID}>
        <RoomDetails/>
      </Modal>
    </>
  );
}

export default LohonoLayout;

const SIMILAR_CAROUSEL_ID = "similar-properties";

const VIDEO_ANCHOR_ID = "video";

const ROOM_DETAILS_MODAL_ID = "room-details-modal";

const BEDROOM_DETAIL_ID = "bedroom-details";

const MAHARAJAH_HOUSE_TITLE = "Fine Dining at Maharajah House";

const MAHARAJAH_HOUSE_DESCRIPTION_P1 = "Witness the bounty of nature at Maharajah House, a culinary journey that will elevate your taste buds and make your palette come alive with its 9-course tasting menu. Experience the highlight of your stay in an intimate outdoor setting under the stars. The exquisite dishes feature homemade cheeses, including Bovres, Halloumi, and Mozzarella to name a few, made from our very own Gir cow’s milk and homegrown fruits and vegetables cooked in our own special spices and oils. Our menu provides you with the Finest & freshest ingredients organically grown in-house at our farm. Each dish is meticulously planned and prepped in advance to give you a complete authentic experience.";

const MAHARAJAH_HOUSE_DESCRIPTION_P2 = "Treat your taste buds to a delicious raclette board produced at our own farm, paired beautifully with wines. Raclette is an aromatic cow’s milk cheese that is salty, with a slightly sweet and nutty flavor. Watch it melt delightfully over an array of bread, pasta and grilled vegetables.";

const WELLNESS_PROMOTION_PROPERTIES = ["ishavilas", "ishavilas-7", "villa-azul", "isp-villa-verde", "isp-villa-branco", "ishanya","windsong"];