import React from "react";
import decodeLocationSlug from "../../../utils/decode-location-slug";
import { getPropertySearchUrl } from "../../../utils/property-search";
// import ISPRAVA_BUILT_LOGO from "../../assets/logo/isprava-built.png";
import { useCarouselScroller } from "../../hooks/use-carousel";
import useClx from "../../hooks/use-clx";
import { Track } from "../AnalyticsManager";
import Carousel from "../Carousel";
import Item from "../Carousel/Item";
import NetworkImg from "../NetworkImg";
import Scroller from "../Scroller";
import clxs from "./recommendation.module.css";
import { useQueryParamValue } from "../../hooks/use-query-param";
import RatingCounts from "../PropertyDetail/RatingCounts";

interface RecommendationProps {
  carouselId: string;
  properties: PropertyItem[];
  title: string;
  subtitle?: string;
  className?: string;
  checkinDateKey?: string; 
  checkoutDateKey?: string;
  adultCountKey?: string;
  childCountKey?: string;
  titleClassName?: string;
  subtitleClassName?: string;
  containerClassName?: string;
  tracking?: string;
}

function Recommendation(props: RecommendationProps) {
  const {
      carouselId,
      properties,
      title,
      subtitle,
      className,
      checkinDateKey,
      checkoutDateKey,
      adultCountKey,
      childCountKey,
      titleClassName,
      subtitleClassName,
      containerClassName,
      tracking = "recommendation",
    } = props, 
    { scrollLeft, scrollRight } = useCarouselScroller(carouselId),
    scx = useClx(clxs.section, className),
    tcx = useClx(titleClassName, clxs.placeholder),
    sucx = useClx(subtitleClassName, clxs.placeholder),
    ccx = useClx(clxs.carousel, containerClassName),
    checkinDate = useQueryParamValue("date", checkinDateKey || "") ,
    checkoutDate = useQueryParamValue("date", checkoutDateKey || ""),
    adultCount = useQueryParamValue("integer", adultCountKey || ""),
    childCount = useQueryParamValue("integer", childCountKey || ""),
    isDatePresent = !!(checkinDate && checkoutDate);

  if (!properties.length) {
    return null;
  }

  return (
    <div className={scx}>
      <div className={tcx}>
        {title}
      </div>
      {subtitle && (
        <div className={sucx}>
          {subtitle}
        </div>
      )}
      <Carousel
        id={carouselId}
        className={ccx}
        threshold={0.9}
        adjust={true}
      >
        {properties.map(
          (property, i) => {
            const {
                thumbnail,
                name,
                address,
                rate,
                isprava_built,
                location_slug,
                property_slug,
                currency_symbol,
                discounted_rate,
                average_ratings,
                ratings_count,
              } = property,
              decodedLocation = decodeLocationSlug(location_slug),
              locationUrl = getPropertySearchUrl(location_slug),
              isShowDiscountedPrice = (discounted_rate > 0) && (rate >0) && (discounted_rate < rate),
              url = isDatePresent ? `${locationUrl}/${property_slug}?checkin_date=${checkinDate?.toISOString()}&checkout_date=${checkoutDate?.toISOString()}&adult_count=${adultCount}&child_count=${childCount}`: `${locationUrl}/${property_slug}`,
              finalPrice = discounted_rate || rate;

            return (
              <Item
                key={i}
                className={clxs.item}
              >
                <Track
                  event={tracking}
                  payload={{
                    ...property,
                    ...decodedLocation,
                    href: url,
                    index: i,
                  }}
                >
                  <a
                    href={url}
                    className={clxs.property}
                    title={name}
                  >
                    <NetworkImg
                      loading="lazy"
                      src={thumbnail.url}
                      alt={thumbnail.alt}
                      className={clxs.thumbnail}
                      mobileQuality={20}
                      desktopQuality={40}
                      mobileDimensions={[400, 300]}
                      desktopDimensions={[600, 400]}
                    />
                    <div className={clxs.title}>
                      {name}
                    </div>
                    <div className={clxs.subtitle}>
                      {address}
                    </div>
                    <div className={ isprava_built ? clxs.logoratingcounts : clxs.ratings}>
                    {ratings_count && average_ratings ?
                      <RatingCounts
                        className={clxs.ratingCounts}
                        ratings_count={ratings_count}
                        average_ratings={average_ratings}
                        theme="recommendationRating"
                      /> : null }
                    </div>
                    <div className={clxs.list}>
                    <div>
                        Start from 
                        {
                          isShowDiscountedPrice && (
                            <span className={clxs.strike}>
                              {`${currency_symbol} ${rate.toLocaleString("en-IN")}`}
                            </span>
                          )
                        }
                      </div>
                      <span className={clxs.rate}>
                        {`${currency_symbol} ${finalPrice.toLocaleString("en-IN")}`}
                      </span>
                      {"/ night* (*Exclusive of taxes)"}
                    </div>
                    {/* {isprava_built && (
                      <img
                        src={ISPRAVA_BUILT_LOGO}
                        alt="isprava-built"
                        className={clxs.ispravaIcon}
                      />
                    )} */}
                  </a>
                </Track>
              </Item>
            );
          },
        )}
      </Carousel>
      <Scroller
        className={clxs.scroller}
        onScrollLeft={scrollLeft}
        onScrollRight={scrollRight}
      />
    </div>
  );
}

export default Recommendation;
