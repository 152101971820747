import React, { ChangeEvent, Fragment, ReactNode, useEffect, useMemo } from "react";
import LLP_STAR from "../../../assets/icons/llp-icon-infinity.svg";
import EXCLAMATION_RED from "../../../assets/icons/exclamation-red-icon.svg";
import CHECK_GREEN from "../../../assets/icons/green-checkmark.svg";
import { useQueryParamValue, useSetQueryParam } from "../../../hooks/use-query-param";
import Button from "../../Button";
import CouponCode from "../../CouponCode";
import Portal from "../../Portal";
import useClx from "../../../hooks/use-clx";
import Switch from "../../Switch";
import DiscountAlert from "../DiscountAlert";
import usePriceCalculator from "../../../hooks/use-price-calculator/use-price-calculator";
import clxs from "./breakdown.module.css";
import Modal, { useShowModal } from "../../../hooks/use-modal";
import IneligibleCriteria from "../IneligibleCriteria";

interface BreakdownProps {
  viewLayout: string;
  className?: string;
  children?: ReactNode;
  loyaltyPointsBalance?: number;
  formId: string;
  couponCodeSearchKey: string;
  availLoyaltyPointsSearchKey: string;
  calculatorId: string;
  propertySlug: string;
  checkinDate: Date | null;
  checkoutDate: Date | null;
  adultCount: number;
  childCount: number;
  couponCode: string;
  configs: PropertyConfigArg[];
  totalNights: number;
  totalGuests: number;
  availLoyaltyPoints?: boolean;
  configMap: Record<number, PropertyConfig>;
  eligibilityPointsObj?: any;
  isDisplayProtectWidget: boolean;
  refundableSearchKey: string;
  quoteIdSearchKey: string;
  isOptedRefundableBooking: boolean;
  setRefundableWidgetHtml?: any;
  formRef?:React.RefObject<HTMLDivElement>;
  // onFormSubmitClick?: any;
}

function Breakdown(props: BreakdownProps) {
  const {
      viewLayout,
      formId,
      couponCodeSearchKey,
      availLoyaltyPointsSearchKey,
      isOptedRefundableBooking,
      refundableSearchKey,
      quoteIdSearchKey,
      className,
      children,
      calculatorId,
      propertySlug,
      checkinDate,
      checkoutDate,
      adultCount,
      childCount,
      configs,
      couponCode,
      totalNights,
      totalGuests,
      availLoyaltyPoints,
      // loyaltyPointsBalance,
      configMap,
      eligibilityPointsObj,
      setRefundableWidgetHtml,
      isDisplayProtectWidget,
      formRef,
      // onFormSubmitClick,
    } = props,
    eligiblePoints = eligibilityPointsObj?.eligible_points,
    inelgibilePoints = eligibilityPointsObj?.ineligible_points,
    inelgibleCriteria = eligibilityPointsObj?.criteria,
    isMultiConfig = viewLayout === "multi_config",
    setCouponCode = useSetQueryParam("string", couponCodeSearchKey),
    setAvailLoyaltyPoints = useSetQueryParam("boolean", availLoyaltyPointsSearchKey),
    setRefundableBooking = useSetQueryParam("boolean", refundableSearchKey),
    setProtectQuoteId = useSetQueryParam("string", quoteIdSearchKey),
    protectQuoteId = useQueryParamValue("string",quoteIdSearchKey),
    // [protectQuoteId, setProtectQuoteId] = useState<string>(""),
    { tentativePrice } = usePriceCalculator(
      calculatorId,
      propertySlug,
      checkinDate,
      checkoutDate,
      adultCount,
      childCount,
      couponCode,
      configs,
      availLoyaltyPoints,
      isDisplayProtectWidget,
      isOptedRefundableBooking,
      protectQuoteId,
    ),
    { widget_details } = tentativePrice,
    { protectHtml } = widget_details,
    template = useMemo(
      () => {
        if (!tentativePrice) {
          return [];
        }

        setProtectQuoteId(widget_details.protectQuoteId);
        setRefundableWidgetHtml(protectHtml);

        const { currency_symbol, property_configs } = tentativePrice, 
          {
            gross_base_rate,
            deposit,
            extra_adults_fare,
            taxes,
            discount,
            rental_charges,
            total,
            discount_method,
            loyalty_points_discount_percentage,
            loyalty_points_discount_percentage_value,
            loyalty_points_redeemed_value,
            premium,
          } = tentativePrice.breakdown,
          discountNode = loyalty_points_discount_percentage_value
            ? {
              label: `${discount_method} Tier (${loyalty_points_discount_percentage}%)`,
              value: `- ${currency_symbol} ${discount.toLocaleString("en-IN")}`,
              labelClx: clxs.llpLabel,
              valueClx: clxs.llpValue,
            }
            : discount
              ? {
                label: "Discount",
                value: `- ${currency_symbol} ${discount.toLocaleString("en-IN")}`,
                labelClx: clxs.heavyLabel,
                valueClx: clxs.heavyValue,
              } : null,
          configBreakdownItems = isMultiConfig
            ? property_configs.reduce(
              (compiled, each) => {
                const {
                  required_rooms,
                  property_config_id,
                  gross_price_all_night_per_room,
                } = each;

                if (!required_rooms) {
                  return compiled;
                }

                const { room_name = "" } = configMap[property_config_id] ?? {},
                  configTemplate = {
                    label: `${room_name}`,
                    value: `${required_rooms} x ${currency_symbol} ${gross_price_all_night_per_room.toLocaleString("en-IN")}`,
                    labelClx: clxs.lightLabel,
                    valueClx: clxs.lightValue,
                  };

                return [
                  ...compiled,
                  configTemplate,
                ];
              },
              [] as BreakdownRenderTemplate[],
            ) : [],
          template = [
            !isMultiConfig && {
              label: "Charges",
              value: `${currency_symbol} ${gross_base_rate.toLocaleString("en-IN")}`,
              labelClx: clxs.lightLabel,
              valueClx: clxs.lightValue,
            },
            ...configBreakdownItems,
            extra_adults_fare ? {
              label: "Additional Guest Charges",
              value: `${currency_symbol} ${extra_adults_fare.toLocaleString("en-IN")}`,
              labelClx: clxs.lightLabel,
              valueClx: clxs.lightValue,
            } : null,
            discountNode,
            loyalty_points_redeemed_value ? {
              label: "Loyalty Points",
              value: `- ${currency_symbol} ${loyalty_points_redeemed_value.toLocaleString("en-IN")}`,
              labelClx: clxs.heavyLabel,
              valueClx: clxs.heavyValue,
            } : null,
            (isOptedRefundableBooking && premium) && {
              label: "Flexible Cancellation Fees",
              value: `${currency_symbol} ${premium.toLocaleString("en-IN")}`,
              labelClx: clxs.lightLabel,
              valueClx: clxs.lightValue,
            },
            {
              label: "Applicable taxes",
              value: `${currency_symbol} ${taxes.toLocaleString("en-IN")}`,
              labelClx: clxs.lightLabel,
              valueClx: clxs.lightValue,
            },
            {
              label: (
                <>
                  Total rental charges
                  <div className={clxs.subtitle}>
                    {`(for ${totalGuests} guests and ${totalNights} nights)`}
                  </div>
                </>
              ),
              value: `${currency_symbol} ${rental_charges.toLocaleString("en-IN")}`,
              labelClx: clxs.heavyDistLabel,
              valueClx: clxs.heavyDistValue,
            },
            {
              label: "Security deposit (Refundable)",
              value: `${currency_symbol} ${deposit.toLocaleString("en-IN")}`,
              labelClx: clxs.heavyLabel,
              valueClx: clxs.heavyValue,
            },
            {
              label: "Total payable",
              value: `${currency_symbol} ${total.toLocaleString("en-IN")}`,
              labelClx: clxs.heavyDistLabel,
              valueClx: clxs.heavyDistValue,
            },
          ].filter(Boolean) as BreakdownRenderTemplate[];

        return template;
      },
      [
        viewLayout,
        tentativePrice,
        totalNights,
        totalNights,
        configMap,
      ],
    ),
    actionText = useMemo(
      () => {
        if (!tentativePrice) {
          return "Make Payment";
        }

        const { currency_symbol } = tentativePrice,
          { total } = tentativePrice.breakdown;

        return `${currency_symbol} ${total.toLocaleString("en-IN")} - Make Payment`;
      },
      [tentativePrice],
    ),
    eligiblePointsContent = useMemo(
      () => {
        if (!eligiblePoints) {
          return null;
        }

        const points = eligiblePoints.toLocaleString("en-IN");

        return `${points} Redeem Infinity Points`;
      },
      [eligiblePoints],
    ),
    // inelgibilePointsContent = useMemo(
    //   () => {
    //     if (!inelgibilePoints) {
    //       return null
    //     }

    //     const points = inelgibilePoints.toLocaleString("en-IN");

    //     return `${points} Points Redemption Unavailable`;
    //   },
    //   [inelgibilePoints],
    // ),
    ccx = useClx(clxs.container, className),
    handleAvailLoyaltyPoints = (e: ChangeEvent<HTMLInputElement>) => {
      const target = e.currentTarget as HTMLInputElement;

      setAvailLoyaltyPoints(target.checked);
    },
    handleCouponCodeChange = (
      couponCode: string,
      replace: boolean = false,
    ) => setCouponCode(couponCode, { replace: replace }),
    handleAutoApplyCoupon = () => {
      if (!tentativePrice) {
        return null;
      }

      if (couponCode === "NONE") {
        return null;
      }

      const { coupon_code: cc } = tentativePrice.breakdown;

      return handleCouponCodeChange(cc || "", true);
    },
    showCriteria = useShowModal(CRITERIA_MODAL_ID),
    handleKnowMore = async (inelgibilePoints: number) => {
      const props = {
        points: inelgibilePoints,
      };

      const criteria = await showCriteria(props as any);

      if(!criteria) {
        return;
      }

    },
    handleOptedInChange = (e: any) => {
      const detailObj = e.detail;
      const isOptedIn = detailObj.sold;
      setRefundableBooking(isOptedIn);
    },
    addOptedInEventChange = () => {
      // if(isDisplayProtectWidget) {
      //   const itemContainer = document.getElementById("protect_container");
      //   itemContainer && itemContainer.addEventListener("pg-change", handleOptedInChange);
      // }
      if(isDisplayProtectWidget) {
        //const htmlString = PROTECT_HTML.__html;
        //const protectScriptIndex = htmlString.search("<script nonce");

        //if(protectScriptIndex > 0) {
          //const protectScript = htmlString.substring(protectScriptIndex,htmlString.length);
          //console.log(protectScript);
          // const executeScript = new Function(protectScript);
          // executeScript();
        //}
  
        document.addEventListener("pg-change", handleOptedInChange);
      }
    },
    scrollIntoForm = () => {
      // onFormSubmitClick();
      formRef?.current?.scrollIntoView({ behavior: "smooth",block: "center", inline: "start"});
    };
    // getReferralPoints = () => {
    //   if (!user) {
    //     return null;
    //   }
    // };

  useEffect(
    () => {
      handleAutoApplyCoupon()
    },
    [tentativePrice],
  );

  useEffect(
    () => {
      addOptedInEventChange();
    },[],
  );

  return (
    <>
      <div className={ccx}>
        <DiscountAlert
          method={tentativePrice?.breakdown.discount_method}
          discountMessage={tentativePrice?.breakdown.discount_message}
          discountAmount={
            tentativePrice?.breakdown.loyalty_points_discount_percentage_value
          }
          currencySymbol={tentativePrice?.currency_symbol}
          className={clxs.discountAlert}
        />
        {eligiblePointsContent && (
          <Switch
            name="avail_loyalty_points"
            className={clxs.llpSwitch}
            onChange={handleAvailLoyaltyPoints}
            checked={availLoyaltyPoints}
          >
            <div className={clxs.llpSwitchContent}>
              <img
                src={LLP_STAR}
                alt="loyalty points"
              />
              {eligiblePointsContent}
            </div>
          </Switch>
        )}
        {(availLoyaltyPoints && eligiblePoints && !inelgibilePoints) && (
          <div className={clxs.pointsSuccess}>
            <img
              src={CHECK_GREEN}
              alt="Points Success"
            />
            <span>
              <b>Congratulations!</b> Points Successfully redeemed
            </span>
          </div>
        )}
        {inelgibilePoints > 0 && (
          <>
            <div className={clxs.ineligiblePointsContent}>
              <img
                src={EXCLAMATION_RED}
                alt="Ineligible Points"
              />
              <span>
                <b>{inelgibilePoints}</b> Points Redemption Unavailable
              </span>

              <button
                type="button"
                className={clxs.knowMoreBtn}
                onClick={() => handleKnowMore(inelgibilePoints)}
              >
                Know More
              </button>
            </div>
            <Modal id={CRITERIA_MODAL_ID}>
              <IneligibleCriteria 
                criteria={inelgibleCriteria} 
                points={inelgibilePoints}
              />
            </Modal>
          </>
        )}
        {template.map((each, key) => (
          <Fragment key={key}>
            <div className={each.labelClx}>{each.label}</div>
            <div className={each.valueClx}>{each.value}</div>
          </Fragment>
        ))}
        <CouponCode
          value={couponCode}
          propertySlug={propertySlug}
          checkinDate={checkinDate?.toISOString()}
          checkoutDate={checkoutDate?.toISOString()}
          adultCount={adultCount || undefined}
          childCount={childCount || undefined}
          onChange={handleCouponCodeChange}
          className={clxs.couponCode}
        />
        {children}
        <Button
          className={clxs.submitDesktop}
          type="submit"
          form={formId}
          onClick={scrollIntoForm}
        >
          {actionText}
        </Button>
      </div>
      <Portal className={clxs.submitMobilePortal}>
        <Button
          className={clxs.submitMobile}
          type="submit"
          form={formId}
          onClick={scrollIntoForm}
        >
          {actionText}
        </Button>
      </Portal>
    </>
  );
}

export default Breakdown;

type BreakdownRenderTemplate = {
  label: ReactNode;
  value: ReactNode;
  labelClx: string;
  valueClx: string;
};

const CRITERIA_MODAL_ID = "ineligible-offer";
